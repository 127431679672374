// react
import { createContext, useEffect, useMemo, useReducer } from 'react'
// firebase
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  createUserWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
  FacebookAuthProvider,
  GoogleAuthProvider,
  confirmPasswordReset,
  verifyPasswordResetCode,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  sendEmailVerification,
  applyActionCode
} from 'firebase/auth'
// service
import { userService } from '~/services'
// hooks
import { useSnackbar } from 'notistack'
// utils
import { sleep } from '~/libs/timing'
import { recursivelyProcessUserToken } from '~/libs/userHelper'
// config
import { FE_HOSTNAME } from '~/config'
// i18n
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'

// ----------------------------------------------------------------------

const trimSensitiveUserInfo = (user) => ({
  // auth related
  _id: user?._id, // same as dbUserId, used as primary Id for user
  authUserId: user?.uid, // from firebase auth
  dbUserId: user?.dbUserId, // from database / mongodb
  role: user?.role,
  // from firebase auth
  emailVerified: user?.emailVerified,
  // available from both
  email: user?.email,
  phone: user?.phone,
  // strictly from db
  name: user?.name,
  title: user?.title,
  description: user?.description,
  photoURL: user?.photoURL,
  backgroundURL: user?.backgroundURL,
  // flag, also from db
  isSuspended: user?.isSuspended,
  isPaidUser: user?.isPaidUser,
  isRegistrationCompleted: user?.isRegistrationCompleted,
  // timestamp
  createdAt: user?.createdAt,
  updatedAt: user?.updatedAt
})

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
}

const reducer = (state, action) => {
  if (action.type === 'INTIALIZE') {
    const { isAuthenticated, user } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    }
  }

  if (action.type === 'RELOAD_PROFILE') {
    const { user } = action.payload

    return {
      ...state,
      user
    }
  }

  return state
}

const FirebaseAuthContext = createContext({
  ...initialState,
  method: 'firebase',
  getToken: () => Promise.resolve(null),
  getRole: () => Promise.resolve(null),
  authorizeService: Promise.resolve(null),
  // login
  login: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  loginWithFacebook: () => Promise.resolve(),
  loginWithToken: () => Promise.resolve(),
  // register
  register: () => Promise.resolve(),
  registerAsGuest: () => Promise.resolve(),
  registerWithGoogle: () => Promise.resolve(),
  registerWithFacebook: () => Promise.resolve(),
  // logout
  logout: () => Promise.resolve(),
  // emailVerification
  requestVerificationEmail: () => Promise.resolve(),
  confirmVerificationEmail: () => Promise.resolve(),
  // profile
  updateProfile: () => Promise.resolve(),
  reloadProfile: () => Promise.resolve(),
  // reset password
  requestResetPassword: () => Promise.resolve(),
  verifyResetPassword: () => Promise.resolve(),
  confirmResetPassword: () => Promise.resolve()
})

// ----------------------------------------------------------------------

/**
 * Context provider for firebase authentication
 * @param {Object} props
 * @param {FirebaseApp} props.firebaseApp
 * @param {children} props.children
 * @returns {JSX.Element}
 */
function FirebaseAuthProvider({ firebaseApp, children }) {
  const { enqueueSnackbar } = useSnackbar()
  const { _ } = useLingui()
  // firebase
  const auth = useMemo(() => getAuth(firebaseApp), [firebaseApp])
  const googleProvider = useMemo(() => {
    const provider = new GoogleAuthProvider()
    provider.addScope('https://www.googleapis.com/auth/userinfo.email')
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile')

    return provider
  }, [])
  const facebookProvider = useMemo(() => {
    const provider = new FacebookAuthProvider()
    return provider
  }, [])

  // context state
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(
    () =>
      // handle auth state change
      onAuthStateChanged(auth, async (firebaseUser) => {
        let profile = null
        if (firebaseUser) {
          // fetch profile from database
          try {
            profile = await fetchProfile({ force: true, maxRetry: 5 })

            dispatch({
              type: 'INTIALIZE',
              payload: {
                isAuthenticated: Boolean(profile),
                user: profile
              }
            })
          } catch (_fetchProfileErr) {
            enqueueSnackbar(
              'There is an error when fetching your user data, please try to sign in again',
              { variant: 'warning' }
            )

            setTimeout(async () => {
              try {
                await logout()
              } catch (_logoutErr) {
                // nothing to do here, so yeah, just do nothing
              }
            }, 1000)
          }
        } else {
          dispatch({
            type: 'INTIALIZE',
            payload: { isAuthenticated: false, user: null }
          })
        }
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  // login
  const login = (email, password) =>
    signInWithEmailAndPassword(auth, email, password)
  const loginWithGoogle = () => signInWithPopup(auth, googleProvider)
  const loginWithFacebook = () => signInWithPopup(auth, facebookProvider)
  const loginWithToken = (token) => signInWithCustomToken(auth, token)

  // register
  const register = (email, password) =>
    createUserWithEmailAndPassword(auth, email, password)
  const registerWithGoogle = () => signInWithPopup(auth, googleProvider)
  const registerWithFacebook = () => signInWithPopup(auth, facebookProvider)
  const registerAsGuest = async (data) => {
    const result = await userService
      .postGuestRegister(data)
      .then((res) => res?.data?.results)

    if (!result?.token) throw new Error('Failed to register as guest')

    await signInWithCustomToken(auth, result.token)
  }

  // password reset
  const requestResetPassword = (email) => sendPasswordResetEmail(auth, email)
  const confirmResetPassword = (code, newPassword) =>
    confirmPasswordReset(auth, code, newPassword)
  const verifyResetPassword = (code) => verifyPasswordResetCode(auth, code)

  const changeEmail = async (newEmail, password) => {
    const magicToken = await authorizeService(userService.putEmail)(
      state?.user?.dbUserId,
      { email: newEmail, password }
    ).then((res) => res.data?.results?.token)

    // if for some reason request to API is successful but:
    // - no magic token is returned
    // - unable to redeem magic token
    // - unable to reauthenticate with new token
    // then we assume that the email is already updated, and user need to login again
    // set this true notify user to login again via snackbar
    let notifyUserToReauthenticate = false

    if (!magicToken) {
      notifyUserToReauthenticate = true
      console.warn(
        '[AUTH/CHANGE-EMAIL] No magic token returned after updating email, user need to reauthenticate manually...'
      )
    } else {
      const authToken = await userService
        .postMagicToken(magicToken)
        .then((res) => res.data.results?.token)

      if (!authToken) {
        notifyUserToReauthenticate = true
        console.warn(
          '[AUTH/CHANGE-EMAIL] No login token returned after redeeming magic token, user need to reauthenticate manually....'
        )
      } else {
        // intentionally not throwing error here, alternative is the same when token is returned
        await loginWithToken(authToken).catch((err) => {
          notifyUserToReauthenticate = true

          console.warn(
            '[AUTH/CHANGE-EMAIL] Failed to reauthenticate with auth token, user need to reauthenticate manually...',
            err
          )
        })
      }
    }

    if (notifyUserToReauthenticate) {
      enqueueSnackbar(
        _(
          msg`Email kamu berhasil diubah, silahkan login kembali untuk melanjutkan!`
        )
      )
    }

    await reloadProfile(true)
  }

  const changePassword = (newPassword) =>
    updatePassword(auth.currentUser, newPassword)

  // email verification
  const requestVerificationEmail = (continuePath = null) => {
    const continueURL = `https://${FE_HOSTNAME}${continuePath}`

    return sendEmailVerification(
      auth.currentUser,
      continuePath ? { handleCodeInApp: false, url: continueURL } : undefined
    )
  }
  const confirmVerificationEmail = (oobCode) => applyActionCode(auth, oobCode)

  // profile
  const updateProfile = async (newProfile, params = {}) => {
    const { maxRetry = 5 } = params

    let retryCount = 0
    let isProfileUpdated = false
    let lastError = null

    do {
      if (retryCount > 0) {
        const delay = Math.pow(2, retryCount - 1) * 2000

        await sleep(delay)
      }

      try {
        const idToken = await auth.currentUser.getIdToken()
        await userService.patchUpdate(state.user._id, newProfile, idToken)

        isProfileUpdated = true
      } catch (err) {
        lastError = err
        retryCount += 1
      }
    } while (!isProfileUpdated && retryCount <= maxRetry)

    if (!isProfileUpdated) throw lastError
  }

  const fetchProfile = async (params = {}) => {
    const { force = false, maxRetry = 5 } = params

    let retryCount = 0

    let newUserProfile = null
    let lastError = null

    do {
      if (retryCount > 0) {
        const delay = Math.pow(2, retryCount - 1) * 2000

        await sleep(delay)
      }

      try {
        const [idToken, customClaims] = await Promise.all([
          auth.currentUser.getIdToken(force),
          auth.currentUser.getIdTokenResult(force).then((res) => res.claims)
        ])
        const dbProfile = await userService.getById(
          customClaims.dbUserId,
          idToken
        )
        newUserProfile = {
          ...auth.currentUser,
          ...dbProfile.data.results,
          dbUserId: customClaims.dbUserId,
          role: customClaims.role
        }
      } catch (err) {
        lastError = err
        retryCount += 1
      }
    } while (!newUserProfile && retryCount <= maxRetry)

    if (!newUserProfile) throw lastError

    return trimSensitiveUserInfo(newUserProfile)
  }

  const reloadProfile = async (force = false) => {
    await auth.currentUser.reload()

    const userProfile = await fetchProfile({ force })

    dispatch({
      type: 'RELOAD_PROFILE',
      payload: { user: userProfile }
    })

    return userProfile
  }

  // reauthenticate
  const reauthenticateWithPassword = async (password) => {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      password
    )

    await reauthenticateWithCredential(auth.currentUser, credential)
  }

  // logout
  const logout = () => signOut(auth)

  // token
  const getToken = async (force = false) => {
    if (!auth?.currentUser) return null

    const token = await auth.currentUser.getIdToken(force)

    return token
  }

  // role
  const getRole = async () => {
    if (!auth?.currentUser) return null

    const idTokenResult = await auth.currentUser.getIdTokenResult()

    return idTokenResult?.claims?.role ?? null
  }

  /**
   * Wrapper to call service function with access token
   * @param {()=>Promise<*>} serviceFunction service function to be called
   * @param {string} errorOnNoToken whether to throw error when token is unavailable, default to false
   * @returns {*} whatever return that serviceFunction give
   */
  const authorizeService =
    (serviceFunction, errorIfTokenUnavailable = false) =>
    async (...args) => {
      const token = await getToken()

      if (!token && errorIfTokenUnavailable)
        throw new Error('Unauthorized access to service')

      // replace `$user` token(s) in args with current user data
      const [_isTokenFound, parsedArgs] = recursivelyProcessUserToken(
        state.user,
        args
      )

      // make sure arguments length is correct, and token is the last argument
      const appliedArgs = [...Array(serviceFunction.length)].map(
        (_arg, i) => parsedArgs[i] ?? undefined
      )
      appliedArgs[serviceFunction.length - 1] = token

      return serviceFunction(...appliedArgs)
    }

  return (
    <FirebaseAuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        // user auth
        getToken,
        getRole,
        authorizeService,
        // login
        login,
        loginWithGoogle,
        loginWithFacebook,
        loginWithToken,
        // register
        register,
        registerWithGoogle,
        registerWithFacebook,
        registerAsGuest,
        // logout
        logout,
        // email verification
        requestVerificationEmail,
        confirmVerificationEmail,
        // profile
        updateProfile,
        reloadProfile,
        // password reset
        requestResetPassword,
        verifyResetPassword,
        confirmResetPassword,
        // reauthenthicate
        reauthenticateWithPassword,
        // change credential
        changeEmail,
        changePassword
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  )
}

export { FirebaseAuthContext, FirebaseAuthProvider }
